<template>
  <m-basic-list
    :cols="grid.cols"
    :rows="grid.rows"
    :sumcols="sumgrid.cols"
    :sumrows="sumgrid.rows"
    @rowDoubleClicked="rowDoubleClicked"
    @get-all="getAll"
    @search="search"
    @clear="clear"
    ref="myList"
    :isshowsum="isshowsum"
    :rowCount="rowCount"
    :gMapCountRemain="gMapCountRemain"
    :type="type"
    @gridReady="gridReady"
    :gridRulesUserDefined="gridRulesUserDefined"
  >
    <component :is="searchComponent" ref="searchComponent"></component>
    <a :href="printerUrl" v-show="false" ref="printerBtn">Yazdır</a>
    <template slot="footer">
      <b-button-group style="float: right">
        <b-dropdown
          squared
          variant="warning"
          size="sm"
          dropup
          right
          text="Dışa Aktar"
        >
          <b-dropdown-item-button @click="exportCsv"
            >CSV</b-dropdown-item-button
          >
          <b-dropdown-item-button @click="exportExcell"
            >Excell</b-dropdown-item-button
          >
        </b-dropdown>

        <!-- <b-button size="sm" @click="exportCsv" squared variant="warning">{{
          $t("btn.exportcsv")
        }}</b-button>
        <b-button size="sm" @click="exportExcell" squared variant="warning"
          >Excell Olarak Dışarı Aktar</b-button
        >
-->
        <b-button
          v-if="type == 'apicargo'"
          size="sm"
          @click="getSelectedRows"
          squared
          variant="success"
          >{{ $t("btn.transfercargo") }}</b-button
        >
        <template v-if="type == 'cargo'">
          <b-button
            size="sm"
            @click="getSelectedRows"
            squared
            variant="primary"
            >{{ $t("btn.opencargo") }}</b-button
          >

          <b-button
            size="sm"
            @click="getSelectedRows('manifest')"
            squared
            variant="success"
            >{{ $t("btn.createmanifest") }}</b-button
          >
        </template>
        <template v-if="type == 'contractaccount'">
          <b-button size="sm" squared variant="info">{{
            $t("btn.selectedtoinvoice")
          }}</b-button>
          <b-button size="sm" squared variant="secondary">{{
            $t("btn.selectedtoclose")
          }}</b-button>
        </template>

        <template v-if="type == 'presebtaccount'">
          <b-button size="sm" squared variant="info">{{
            $t("btn.selectedtoinvoice")
          }}</b-button>
          <b-button size="sm" squared variant="secondary">{{
            $t("btn.selectedtoclose")
          }}</b-button>
        </template>

        <template v-if="type == 'cargopersondelivery'">
          <b-button @click="printAction" size="sm" squared variant="info"
            >Yazdır</b-button
          >
        </template>

        <template v-if="type == 'invoice'">
          <b-button size="sm" @click="sendInvoice" squared variant="primary"
            >Gönder</b-button
          >
          <b-button size="sm" @click="statusInvoice" squared variant="info"
            >Durum Sorgula</b-button
          >
          <b-button size="sm" @click="downloadInvoice" squared variant="danger"
            >Faturayı Aç</b-button
          >
        </template>
      </b-button-group>
    </template>

    <m-loading :msg="loadingMsg" v-if="loadingShow" />
  </m-basic-list>
</template>

<script>
let sumCols = {
  reselleraccount: [
    { field: "CLAIMKIND", headerName: "İşlem Tipi" },
    {
      field: "CREDITAMOUNT",
      headerName: "Borç",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
    {
      field: "DEBTAMOUNT",
      headerName: "Alacak",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
    {
      field: "CLAIMAMOUNT",
      headerName: "Şube Hakedişi",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
    {
      field: "CLAIMPAYED",
      headerName: "Hakediş Ödemesi",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
    {
      field: "REMAINDERAMOUNT",
      headerName: "Bakiye",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
  ],
  contractaccount: [
    {
      field: "SHIPPINGAMOUNT",
      headerName: "Taşıma Ücreti",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },

    {
      field: "CREDITAMOUNT",
      headerName: "Borç",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
    {
      field: "DEBTAMOUNT",
      headerName: "Alacak",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },

    {
      field: "CREDITCUSTOMER",
      headerName: "Müşteri Alacağı",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
    {
      field: "PAYEDCUSTOMER",
      headerName: "Müşteriye Ödenen",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
    {
      field: "REMAINDERAMOUNT",
      headerName: "Bakiye",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
  ],
  resellercashbox: [
    { field: "TYPE", headerName: "TL" },

    {
      field: "CREDITAMOUNT",
      headerName: "Borç",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
    {
      field: "DEBTAMOUNT",
      headerName: "Alacak",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
    {
      field: "REMAINDERAMOUNT",
      headerName: "Bakiye",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
  ],
  cargo: [
    {
      field: "TOTAL",
      headerName: "Gönderi Sayısı",
      valueFormatter(t) {
        return parseInt(t.value);
      },
    },
    {
      field: "PIECE",
      headerName: "Adet",
      valueFormatter(t) {
        return parseInt(t.value);
      },
    },
    {
      field: "NETAMOUNT",
      headerName: "Gönderi Ücreti",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
    {
      field: "TOTALAMOUNT",
      headerName: "Alıcıdan Tahsil Edilecek Ücret",
      valueFormatter(t) {
        return parseFloat(t.value).toFixed(2);
      },
    },
    {
      field: "DESI",
      headerName: "Desi",
      valueFormatter(t) {
        return parseInt(t.value);
      },
    },
    {
      field: "WEIGHT",
      headerName: "Ağırlık",
      valueFormatter(t) {
        return parseInt(t.value);
      },
    },
  ],
  resellercashbox: [
    { field: "TYPE", headerName: "TL" },

    { field: "CREDITAMOUNT", headerName: "Borç" },
    { field: "DEBTAMOUNT", headerName: "Alacak" },
    { field: "REMAINDERAMOUNT", headerName: "Bakiye" },
  ],
  dailycashbox: [
    { field: "CREDITAMOUNT", headerName: "Borç" },
    { field: "DEBTAMOUNT", headerName: "Alacak" },
    { field: "REMAINDER", headerName: "Bakiye" },
  ],
  sumcashbox: [
    { field: "CREDITAMOUNT", headerName: "Borç" },
    { field: "DEBTAMOUNT", headerName: "Alacak" },
    { field: "REMAINDER", headerName: "Bakiye" },
  ],
  cashbox: [
    { field: "CREDITAMOUNT", headerName: "Borç" },
    { field: "DEBTAMOUNT", headerName: "Alacak" },
    { field: "REMAINDER", headerName: "Bakiye" },
  ],

  sumearning: [
    { field: "DELIVERYRETURNAMOUNT", headerName: "Dağıtım" },
    { field: "ACCEPTAMOUNT", headerName: "Alım" },
    { field: "TOTALEARNING", headerName: "Toplam Hakediş" },
    { field: "RESELLERPAYED", headerName: "Ödenen" },
    { field: "WAITEDAMOUNT", headerName: "Kalan" },
  ],

  earning: [
    { field: "DELIVERYRETURNAMOUNT", headerName: "Dağıtım" },
    { field: "ACCEPTAMOUNT", headerName: "Alım" },
    { field: "TOTALEARNING", headerName: "Toplam Hakediş" },
    { field: "RESELLERPAYED", headerName: "Ödenen" },
    { field: "WAITEDAMOUNT", headerName: "Kalan" },
  ],

  sumcontracttrans: [
    { field: "CREDITAMOUNT", headerName: "Borç" },
    { field: "DEBTAMOUNT", headerName: "Alacak" },
    { field: "REMAINDER", headerName: "Bakiye" },
  ],

  contracttrans: [
    { field: "CREDITAMOUNT", headerName: "Borç" },
    { field: "DEBTAMOUNT", headerName: "Alacak" },
    { field: "REMAINDER", headerName: "Bakiye" },
  ],

  cargotranskind: [
    { field: "SENDINGCOUNT", headerName: "Gönderim" },
    { field: "DELIVERYCOUNT", headerName: "Teslim" },
    { field: "TOTALCOUNT", headerName: "Toplam" },
  ],
};
import swal from "sweetalert";
import api from "../../api";
import config from "../../config";
import { mapGetters } from "vuex";
export default {
  beforeMount() {
    this.type = this.$route.params.type;

    api.getUserDefinedTemplate(this.type).then((userDefinedTemplate) => {
      api.getCols(this.type).then((res) => {
        if (this.type == "cargo")
          console.log("getCols", res);
        const gridOptions = this.$refs.myList.getGridOptions();
        if (this.type == "cargo")
          console.log("cargo grid options", res);
        if (userDefinedTemplate.data.status) {
          //mount olmasını beklememiz lazım
          this.gridRulesUserDefined = userDefinedTemplate.data.data.rules;
          this.gridColumnStateUserDefined =
            userDefinedTemplate.data.data.columnState || null;

          gridOptions.getRowStyle = (params) => {
            if (!params.data) return;
            const callback = (item) => {
              const colDef = params.node.columnApi.columnController.columnDefs;
              const col = colDef.find((col) => col.field == item.colId);

              if (!col) {
                return null;
              }

              let colValue = params.data[item.colId];

              if ("valueFormatter" in col) {
                colValue = col.valueFormatter({ value: colValue });
              }

              switch (item.condition) {
                case "=":
                  if (colValue == item.conditionString) {
                    return {
                      background: item.background,
                      color: item.color,
                    };
                  }

                  break;
                case "!=":
                  if (colValue != item.conditionString) {
                    return {
                      background: item.background,
                      color: item.color,
                    };
                  }
                  break;
                case ">":
                  if (colValue > item.conditionString) {
                    return {
                      background: item.background,
                      color: item.color,
                    };
                  }
                  break;
                case "<":
                  if (colValue < item.conditionString) {
                    return {
                      background: item.background,
                      color: item.color,
                    };
                  }
                  break;
                case ">=":
                  if (colValue >= item.conditionString) {
                    return {
                      background: item.background,
                      color: item.color,
                    };
                  }
                  break;
                case "<=":
                  if (colValue <= item.conditionString) {
                    return {
                      background: item.background,
                      color: item.color,
                    };
                  }
                  break;
                default:
                  return null;
                  break;
              }
            };

            for (let item of userDefinedTemplate.data.data.rules) {
              let res = callback(item);
              if (res) {
                return res;
              }
            }
          };
        }

        this.grid.cols = res.data;
        this.exportService.cols = JSON.parse(JSON.stringify(res.data));
        setTimeout(() => {
          if (this.gridColumnStateUserDefined) {
            gridOptions.columnApi.setColumnState(
              this.gridColumnStateUserDefined
            );
          }
        }, 750);
        if (
          [
            "contractaccount",
            "reselleraccount",
            "resellercashbox",
            "cargo",

            "dailycashbox",
            "sumcashbox",
            "cashbox",
            "sumearning",
            "earning",
            "sumcontracttrans",
            "contracttrans",
            "cargotranskind",
          ].indexOf(this.type) > -1
        ) {
          this.isshowsum = true;
          this.sumgrid.cols = sumCols[this.type];
        }
      });
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.getAll();
    });
  },
  data() {
    return {
      gridColumnStateUserDefined: null,
      gridRulesUserDefined: [],

      rowCount: 0,
      gMapCountRemain: 0,
      showTransferModal: false,
      transferModalData: null,
      type: null,
      gridApi: null,
      grid: {
        cols: [],
        rows: [],
      },
      isshowsum: false,
      sumgrid: {
        cols: [],
        rows: [],
      },

      loadingMsg: "",
      loadingShow: false,

      selectedRowId: null,

      exportService: {
        cols: [],
      },

      onceRunGrid: false,
    };
  },
  computed: {
    ...mapGetters(["getPerms"]),
    searchComponent() {
      return "search-" + this.type;
    },
    printerUrl() {
      return `prdr://courier_temp%20ID=${this.selectedRowId}%200`;
    },
  },
  methods: {
    gridReady() {
      console.log("gridReady", Date.now());
    },
    printAction() {
      let rows = this.$refs.myList.getSelectedRows();
      if (rows.length) {
        if (rows.length === 1) {
          this.selectedRowId = rows[0].ID;
          this.$nextTick(() => {
            this.$refs.printerBtn.click();
          });
        } else {
          swal(
            this.$t("dialog.notice"),
            "En fazla 1 adet Kargo seçebilirsiniz!",
            "error"
          );
        }
      } else {
        swal(
          this.$t("dialog.notice"),
          this.$t("dialog.selectmincargo"),
          "error"
        );
      }
    },
    //this.getSelectedRows('cargopersondelivery')

    exportCsv() {
      const gridApi = this.$refs.myList.getGridApi();
      const params = {
        processCellCallback: function (cell) {
          // Manipulate the value however you need.
          if ("valueFormatter" in cell.column.userProvidedColDef) {
            return cell.column.userProvidedColDef.valueFormatter(cell);
          }
          return cell.value;
        },
      };

      gridApi.exportDataAsCsv(params);
    },
    exportExcell() {
      api
        .exportExcell({
          type: this.type,
          cols: this.exportService.cols,
          search: this.getSearchParameters(),
        })
        .then((res) => {
          window.open(config.API_URL + res.data.fileName);
        });
    },
    sendInvoice() {
      let rows = this.$refs.myList.getSelectedRows();
      if (rows.length) {
        if (rows.length === 1) {
          this.loadingMsg = "E-Fatura gönderiliyor, lütfen bekleyiniz..";
          this.loadingShow = true;
          api
            .sendInvoice(rows[0].ID)
            .then((res) => {
              if (res.data.status) {
                if (res.data.data.success) {
                  swal("Başarılı", "Başarıyla gönderildi.", "success");
                } else {
                  swal(
                    "Uyarı",
                    "E-Fatura gönderilirken oluşan hatalar:" +
                      res.data.data.error,
                    "error"
                  );
                }
              } else {
                swal(
                  "Uyarı",
                  "Gönderilirken bir hata oluştu:" + res.data.errMsg,
                  "error"
                );
              }
            })
            .catch((err) => {
              swal(
                "Sistem Hatası",
                "Bir hata oluştu:" + err.toString(),
                "error"
              );
            })
            .finally(() => {
              this.loadingShow = false;
            });
        } else {
          swal(
            this.$t("dialog.notice"),
            "En fazla 1 adet Kargo seçebilirsiniz!",
            "error"
          );
        }
      } else {
        swal(this.$t("dialog.notice"), "Kargo seçiniz!", "error");
      }
    },
    statusInvoice() {
      let rows = this.$refs.myList.getSelectedRows();
      if (rows.length) {
        if (rows.length === 1) {
          this.loadingMsg =
            "E-Faturanın durumu sorgulanıyor lütfen bekleyiniz..";
          this.loadingShow = true;
          api
            .statusInvoice(rows[0].ID)
            .then((res) => {
              if (res.data.status) {
                if (res.data.data.success) {
                  swal("Başarılı", "Başarıyla durum sorgulandı.", "success");
                } else {
                  swal(
                    "Uyarı",
                    "E-Fatura durumu sorgulanırken oluşan hatalar:" +
                      res.data.data.error,
                    "error"
                  );
                }
              } else {
                swal(
                  "Uyarı",
                  "Durum sorgulanırken bir hata oluştu:" + res.data.errMsg,
                  "error"
                );
              }
            })
            .catch((err) => {
              swal(
                "Sistem Hatası",
                "Bir hata oluştu:" + err.toString(),
                "error"
              );
            })
            .finally(() => {
              this.loadingShow = false;
            });
        } else {
          swal(
            this.$t("dialog.notice"),
            "En fazla 1 adet Kargo seçebilirsiniz!",
            "error"
          );
        }
      } else {
        swal(this.$t("dialog.notice"), "Kargo seçiniz!", "error");
      }
    },
    downloadInvoice() {
      let rows = this.$refs.myList.getSelectedRows();
      if (rows.length) {
        if (rows.length === 1) {
          this.loadingMsg =
            "E-Fatura görüntüsü hazırlanıyor, lütfen bekleyiniz..";
          this.loadingShow = true;
          api
            .downloadInvoice(rows[0].ID)
            .then((res) => {
              if (res.data.status) {
                if (res.data.data.success) {
                  swal("Başarılı", "E-Fatura görüntüsü hazırlandı.", "success");
                  window.open(config.API_URL + res.data.data.file, "new");
                } else {
                  swal(
                    "Uyarı",
                    "E-Fatura görüntüsü hazırlanırken bir sorun oluştu:" +
                      res.data.data.error,
                    "error"
                  );
                }
              } else {
                swal(
                  "Uyarı",
                  "E-Fatura görüntüsü hazırlanırken bir sorun oluştu:" +
                    res.data.errMsg,
                  "error"
                );
              }
            })
            .catch((err) => {
              swal(
                "Sistem Hatası",
                "Bir hata oluştu:" + err.toString(),
                "error"
              );
            })
            .finally(() => {
              this.loadingShow = false;
            });
        } else {
          swal(
            this.$t("dialog.notice"),
            "En fazla 1 adet Kargo seçebilirsiniz!",
            "error"
          );
        }
      } else {
        swal(this.$t("dialog.notice"), "Kargo seçiniz!", "error");
      }
    },
    getSelectedRows(customType = "") {
      let rows = this.$refs.myList.getSelectedRows();
      if (rows.length) {
        if (this.type == "apicargo") {
          if (rows.length === 1) {
            parent.postMessage(
              { event: "apicargo-transfer-click-event", data: rows[0].ID },
              "*"
            );
          } else {
            swal(
              this.$t("dialog.notice"),
              "En fazla 1 adet Kargo seçebilirsiniz!",
              "error"
            );
          }
        } else if (this.type == "cargo") {
          if (customType == "manifest") {
            parent.postMessage(
              {
                event: "cargo-create-manifest-click-event",
                data: rows
                  .map((item) => {
                    return item.ID;
                  })
                  .join("-"),
              },
              "*"
            );
          } else {
            if (rows.length === 1) {
              parent.postMessage(
                { event: "cargo-track-click-event", data: rows[0].ID },
                "*"
              );
            } else {
              swal(
                this.$t("dialog.notice"),
                this.$t("dialog.selectmincargo"),
                "error"
              );
            }
          }
        } else if (type == "cargopersondelivery") {
          if (rows.length === 1) {
            return rows[0].ID;
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.selectmincargo"),
              "error"
            );
          }
        }
      } else {
        swal(this.$t("dialog.notice"), this.$t("dialog.selectcargo"), "error");
      }
      return false;
    },
    clear() {
      this.$refs.searchComponent.clearSearch();
    },
    getAll() {
      this.find({});
    },
    getSearchParameters() {
      const searchData = this.$refs.searchComponent.getSearch();
      const search = {
        between: [],
        like: {},
        eq: {}
      };

      const fields = Object.keys(searchData);

      const doNothing = fields.map((item) => {
        if (
          [
            "SAVEDATE",
            "DELIVERYDATE",
            "ACCEPTDATE",
            "DUEDATE",
            "TRANSDATE",
            "CARGODATE",
            "CANCELDATE",
            "ACCEPTCLIENTDATE",
            "RETURNDATE"
          ].indexOf(item) > -1
        ) {
          if (searchData[item][0] && searchData[item][1]) {
            search["between"].push([
              item,
              searchData[item][0],
              searchData[item][1],
            ]);
          }
        } else if (
          [
            "SENDERFIRMNAME",
            "SENDERFULLNAME",
            "TAKERFIRMNAME",
            "TAKERFULLNAME",
            "ROLENAME",
            "CITYNAME",
            "COUNTYNAME",
            "TOWNNAME",
            "FIRMNAME",
            "FULLNAME",
            "PACKAGENAME",
            "VEHICLENAME",
            "BROKERAGETITLE",
            "AUTHORNAME",
            "AGENTTITLE",
            "ADDRESSNAME",
            "RESELLERNAME",
            "RESELLERCODE",
            "FIRSTNAME",
            "LASTNAME",
            "USERNAME",
            "RETURNVARIABLENAME",
            "COUPONNAME",
            "SENDINGTYPENAME",
            "PAYTYPENAME",
            "CARGOTYPENAME",
            "BARCODE",
            "INTEGRATIONBARCODE",
          ].indexOf(item) > -1
        ) {
          if (searchData[item]) {
            search["like"][item] = searchData[item];
          }
      }

      else if (
          [
            "EQ_INTEGRATIONBARCODE",
          ].indexOf(item) > -1
      ) {
        if (searchData[item]) {
          search["eq"][item] = searchData[item];
        }
      }

      else {
          if (searchData[item] !== null) {
            search[item] = searchData[item];
          }
        }
      });

      return search;
    },
    setDataSource(search = {}) {
      const that = this;
      that.rowCount = 0;
      const gridApi = that.$refs.myList.getGridApi();
      /*
      let dataSourceX = {
        rowCount: 0,
        getRows(params) {
          params.successCallback([], 0);
        },
      };
      gridApi.setDatasource(dataSourceX);*/
      const dataSource = {
        rowCount: null,
        getRows(params) {
          search.limit = [params.startRow, params.endRow - params.startRow];

          let data = [];
          let rowCount = 0;
          that.sumgrid.rows = [];
          api
            .getDataForBasicList(that.type, search, params.sortModel)
            .then((res) => {
              if (res.data.status && !res.data.msg) {
                if (res.data.data.rows.length) {
                  if ("total" in res.data.data) {
                    if (that.type == "contractaccount") {
                      const totals = res.data.data.total[0];
                      let remainder = 0.0;

                      remainder =
                        parseFloat(totals.CREDITAMOUNT) +
                        parseFloat(totals.PAYEDCUSTOMER) -
                        (parseFloat(totals.CREDITCUSTOMER) +
                          parseFloat(totals.DEBTAMOUNT));

                      totals.REMAINDERAMOUNT = remainder.toFixed(2);
                      that.sumgrid.rows = [totals];
                    } else if (that.type == "cargo") {
                      const totals = res.data.data.total[0];

                      that.sumgrid.rows = [totals];
                    } else if (that.type == "reselleraccount") {
                      that.sumgrid.rows = res.data.data.total;
                      if (that.type == "reselleraccount") {
                        const sumgridtotal = {
                          CLAIMKIND: "Genel Toplam",
                          CREDITAMOUNT: res.data.data.total.reduce(
                            (total, item) => {
                              return (
                                parseFloat(total) +
                                parseFloat(item.CREDITAMOUNT)
                              );
                            },
                            0
                          ),
                          DEBTAMOUNT: res.data.data.total.reduce(
                            (total, item) => {
                              return (
                                parseFloat(total) + parseFloat(item.DEBTAMOUNT)
                              );
                            },
                            0
                          ),
                          CLAIMAMOUNT: res.data.data.total.reduce(
                            (total, item) => {
                              return (
                                parseFloat(total) + parseFloat(item.CLAIMAMOUNT)
                              );
                            },
                            0
                          ),
                          CLAIMPAYED: res.data.data.total.reduce(
                            (total, item) => {
                              return (
                                parseFloat(total) + parseFloat(item.CLAIMPAYED)
                              );
                            },
                            0
                          ),
                          REMAINDERAMOUNT: res.data.data.total.reduce(
                            (total, item) => {
                              return (
                                parseFloat(total) +
                                parseFloat(item.REMAINDERAMOUNT)
                              );
                            },
                            0
                          ),
                        };
                        sumgridtotal.REMAINDERAMOUNT =
                          sumgridtotal.REMAINDERAMOUNT -
                          sumgridtotal.CLAIMPAYED;
                        that.sumgrid.rows.push(sumgridtotal);
                      }
                    } else if (that.type == "resellercashbox") {
                      const selected = res.data.data.total[0];
                      let rows = [];
                      let total = [];
                      if (res.data.data.prev.length) {
                        const prev = res.data.data.prev[0];
                        rows.push(prev);

                        total.push({
                          TYPE: "Genel Toplam",
                          CREDITAMOUNT: (
                            parseFloat(selected.CREDITAMOUNT) +
                            parseFloat(prev.CREDITAMOUNT)
                          ).toFixed(2),
                          DEBTAMOUNT: (
                            parseFloat(selected.DEBTAMOUNT) +
                            parseFloat(prev.DEBTAMOUNT)
                          ).toFixed(2),
                          REMAINDERAMOUNT: (
                            parseFloat(selected.REMAINDERAMOUNT) +
                            parseFloat(prev.REMAINDERAMOUNT)
                          ).toFixed(2),
                        });
                      }
                      rows.push(selected);
                      if (total.length) {
                        rows.push(total[0]);
                      }
                      that.sumgrid.rows = rows;
                    } else if (
                      [
                        "dailycashbox",
                        "sumcashbox",
                        "cashbox",
                        "sumearning",
                        "earning",
                        "sumcontracttrans",
                        "contracttrans",
                        "cargotranskind",
                      ].includes(that.type)
                    ) {
                      const totals = res.data.data.total[0];

                      that.sumgrid.rows = [totals];
                    }
                  }
                  var lastRow = -1;
                  if (res.data.data.rowCount <= params.endRow) {
                    lastRow = res.data.data.rowCount;
                  }

                  params.successCallback(res.data.data.rows, lastRow);
                  that.rowCount = res.data.data.rowCount;
                  if (that.type == 'apicargo') {
                    that.gMapCountRemain = res.data.data.gMapCountRemain;
                  }
                  //that.grid.rows = res.data.data.rows;
                } else {
                }
              } else if (res.data.status && res.data.msg) {
                swal(that.$t("dialog.info"), res.data.msg, "info");
                params.successCallback([], 0);
              } else {
                swal(
                  that.$t("dialog.notice"),
                  that.$t("dialog.anerrorhasccurred") + res.data.errMsg,
                  "error"
                );
                params.successCallback([], 0);
              }
            })
            .catch((err) => {
              swal(
                that.$t("dialog.systemerror"),
                that.$t("dialog.anerrorhasccurred") + err.toString(),
                "error"
              );
            });
        },
      };

      gridApi.setDatasource(dataSource);
    },
    search() {
      const search = this.getSearchParameters();
      this.find(search);
    },
    find(search = {}) {
      this.grid.rows = [];

      this.sumgrid.rows = [];
      this.setDataSource(search);
      return;
    },
    rowDoubleClicked(row) {
      row.data.type = this.type;

      if (this.type == "cargo" && !this.getPerms("cargolist")) {
        return;
      }

      /*
      if (this.type == "cargo" && this.getPerms("cargobasic")) {
        row.data.type = "cargotrack";
      }*/
      /*
      if (this.type == "cargo") {
        row.data.type = "cargotrack";
      }
*/
      if (this.type == "apicargo") {
        swal(
          this.$t("dialog.notice"),
          this.$t("dialog.integrationnoshowcargo"),
          "error"
        );
        return;
      }

      if (this.type == "reselleraccount" || this.type == "contractaccount") {
        row.data.type = "cargotrack";
        row.data.ID = row.data.CARGOID;
      }

      parent.postMessage(
        { event: "grid-row-double-click-event", data: row.data },
        "*"
      );
    },
  },
};
</script>

<style lang="scss">
.m-list-input-area {
  .tabs {
    width: 100%;
  }
}
</style>
